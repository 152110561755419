import React from "react"
import {graphql} from "gatsby"
import Helmet from 'react-helmet'

import "../assets/styles/styles.scss"
import stLogo from "../assets/images/svg/st-logo-light.svg"

const HomePage = ({data}) => {
  return (
    <div
      aria-describedby="pageTitle"
      className={`js-page-focus page under-construction`}
      role="region"
      tabIndex="-1"
    >
      <Helmet
        htmlAttributes={{lang: 'en'}}
        title={`${data.site.siteMetadata.title}${data.site.siteMetadata.title.slogan}`}
        meta={[
          {name: 'description', content: data.site.siteMetadata.siteDescription},
          {name: 'keywords', content: data.site.siteMetadata.siteKeywords},
          {name: 'robots', content: "follow"},
          {name: 'og:title', content: data.site.siteMetadata.title},
          {name: 'og:description', content: data.site.siteMetadata.siteDescription},
          {name: 'og:image', content: data.site.siteMetadata.socialMeta.image},
          {name: 'og:url', content: data.site.siteMetadata.socialMeta.url},
          {name: 'og:site_name', content: data.site.siteMetadata.socialMeta.site_name},
          {name: 'twitter:image:alt', content: data.site.siteMetadata.socialMeta.image_alt},
          {name: 'twitter:card', content: "summary"}
        ]}
        link={[
          {"rel": "icon", "type": "image/png", "href": data.site.siteMetadata.siteFavicon},
          {"rel": "canonical", "href": 'https://straythoughts.ca/'}
        ]}
      />
      <div className="lines">
      <div className="line"></div>
      <div className="line"></div>
      <div className="line"></div>
    </div>
      <section className="message">
        <div className="ico">
          <img src={stLogo} alt="Stray Thoughts shadow face logo" />
        </div>
        <h1>Welcome to Stray Thougths on Storytelling</h1>
        <p>This site is currently under construction. In the meantime visit my <a href="https://vocal.media/authors/kojo-gyan" title="See Kojo's Vocal profile">vocal.media profile</a> to see what i'm currently writing.</p>
      </section>
    </div>
  )
}

export const query = graphql`
  query getFeatured {
    site {
      siteMetadata {
        title
        slogan
        siteUrl
        siteImage
        siteFavicon
        siteDescription
        siteKeywords
        socialMeta {
          image
          url
          site_name
          image_alt
        }
      }
    }
  }
`
export default HomePage
